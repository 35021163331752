.scroll {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $color-green;
  z-index: 2;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25px;

  &::before,
  &::after {
    content: '';
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(.5);
    background-color: $color-green;
    opacity: .6;
    position: absolute;
    left: 50%;
    top: 50%;
  }

  &::before {
    width: 80px;
    height: 80px;
    z-index: -1;
    animation: 2s 2s infinite pulse;
  }

  &::after {
    width: 110px;
    height: 110px;
    z-index: 3;
    animation: 2s infinite pulse;
  }

  &__link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    position: relative;
  }

  &__mouse {
    width: 20px;
    height: 30px;
    border-radius: 40px;
    background-color: $color-transparent;
    border: 2px solid $color-white;
    position: relative;

    &::before {
      content: '';
      width: 6px;
      height: 6px;
      background-color: $color-white;
      border-radius: 50%;
      position: absolute;
      transform: translateX(-50%);
      top: 6px;
      left: 50%;
      animation: 2s .1s infinite mouse-move;
    }
  }
}

@keyframes mouse-move {
  0% {
    transform: translate(-50%, 0);
    opacity: 0;
  }


  20%,
  50% {
    opacity: 1;
  }

  100% {
    transform: translate(-50%, 10px);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(.4);
    opacity: .6;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}