.footer {
  background-color: $color-white;
  border-top: 1px solid $color-grey-bright;

  &__url {
    color: $color-navyblue;
  }

  &__copyright {
    padding: 40px 0 30px 0;
    background-color: $color-green;
  }

  &__content {
    margin: 0 auto;
    width: 100%;
    max-width: $layout-width;
    padding: 0 20px;
  }

  &__brand {
    display: table;
    margin: 0 auto;
  }

  &__brand-img {
    height: 25px;
    text-align: center;
    display: block;
    margin-bottom: 15px;

    svg {
      height: 25px;

      path,
      rect {
        fill: $color-white;
      }
    }
  }

  &__text {
    font-size: 14px;
    font-weight: $font-weight-semibold;
    color: $color-white;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    display: block;
  }

  &__powered-by {
    margin-top: 5px;
    font-size: 10px;

    a {
      color: $color-white;
    }
  }
}

@media (min-width: $mq-md) {
  .footer {
    &__contact-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 45px;
    }
  }
}

.p404-footer {
  &__content {
    flex: 1;
    padding: 0 45px;
  }

  &__copyright {
    background-color: $color-white;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__text {
    color: $color-navyblue;
  }

  &__powered-by {
    a {
      color: $color-navyblue;
    }
  }
}

@media (min-width: $mq-lg) {
  .p404-footer {
    display: flex;

    &__content {
      display: block;
      height: 145px;
    }

    &__copyright {
      background-color: $color-green;
      width: 450px;
      padding: 0;
    }

    &__text {
      color: $color-white;
    }

    &__powered-by {
      a {
        color: $color-white;
      }
    }
  }
}

@media (min-width: $mq-xlg) {
  .p404-footer {
    &__copyright {
      width: 600px;
    }
  }
}

@media (min-width: $mq-xxlg) {
  .p404-footer {
    &__copyright {
      width: 680px;
    }

    &__content {
      display: flex;
      height: 85px;
    }
  }
}