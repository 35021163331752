.about-us {
  margin-top: 20px;
  padding-top: 80px;
  padding-bottom: 45px;

  &__image {
    width: 100%;
    max-width: 550px;
    margin: 0 auto 50px auto;
  }

  &__content {
    flex: 1;
  }

  &__text {
    text-align: justify;
  }
}

@media (min-width: $mq-md) {
  .about-us {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-bottom: 80px;

    &__image {
      width: 50%;
      margin: 0 0 0 40px;
      flex-shrink: 0;
    }
  }
}