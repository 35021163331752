.offer {
  margin: -10px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  &__card {
    width: 100%;
    display: flex;
  }

  &__content {
    background-color: $color-white;
    border: 1px solid $color-grey-bright;
    padding: 40px 20px;
    margin: 10px;
    display: flex;
    align-items: center;
    flex: 1;
    z-index: 1;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      width: 120%;
      height: 100%;
      background-color: $color-green;
      transform: skewX(-10deg) translateX(-120%);
      z-index: -1;
      transition: transform .3s ease;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      &::before {
        transform: skewX(-10deg) translateX(-10%);
      }

      .offer__heading {
        color: $color-white;
      }

      .offer__image path {
        fill: $color-white;
      }
    }
  }

  &__heading {
    font-size: 16px;
    color: $color-navyblue;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color .3s ease;
  }

  &__image {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    flex-shrink: 0;

    path {
      transition: fill .3s ease;
    }
  }
}

@media (min-width: $mq-sm) {
  .offer {
    &__card {
      width: 50%;
    }

    &__heading {
      font-size: 13px;
    }

    &__image {
      margin-right: 15px;
    }
  }
}

@media (min-width: $mq-md) {
  .offer {
    &__content {
      padding: 60px 50px;
    }

    &__heading {
      font-size: 16px;
    }

    &__image {
      width: 45px;
      height: 45px;
      margin-right: 30px;
    }
  }
}

@media (min-width: $mq-lg) {
  .offer {
    &__card {
      width: 33.33333%;
    }

    &__content {
      padding: 60px 40px;
    }
  }
}

@media (min-width: $mq-xlg) {
  .offer {
    &__content {
      padding: 70px 60px;
    }

    &__heading {
      font-size: 18px;
    }

    &__image {
      margin-right: 32px;
    }
  }
}