.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__section {
    flex: 1;
  }

  &__header {
    text-align: center;
    margin-top: 100px;
  }

  &__content {
    margin: 60px 0 100px 0;

    h2,
    h3,
    h4,
    h5 {
      font-size: 22px;
      margin-bottom: 10px;
    }

    ol {
      padding-left: 30px;
    }

    ul {
      padding-left: 30px;

      & > li {
        list-style-type: disc;
      }
    }

    a {
      color: $color-green;
      transition: color .15s ease;

      &:focus,
      &:hover {
        color: $color-navyblue;
      }
    }

    .text-center {
      text-align: center;
    }

    .text-right {
      text-align: right;
    }
  }
}