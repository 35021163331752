.header {
  background-color: $color-white-dark;
  border-bottom: 1px solid $color-grey-bright;
  position: relative;

  &__container {
    width: 100%;
    max-width: $layout-width;
    height: 100%;
    padding: 30px 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
  }

  &__overflow-wrapper {
    overflow: hidden;
  }

  &__content {
    margin: 50px auto;
    flex-shrink: 0;
  }

  &__title {
    font-size: 34px;
    font-weight: $font-weight-bold;
    line-height: 44px;
    color: $color-navyblue;
    position: relative;

    &::before {
      content: '';
      width: 50px;
      height: 4px;
      border-radius: 6px;
      background-color: $color-green;
      position: absolute;
      top: -25px;
      left: 0;
    }
  }

  &__text {
    display: block;
  }

  &__highlighted {
    color: $color-green;
  }

  &__buttons {
    margin-top: 45px;
    display: flex;
  }

  &__btn {
    font-size: 12px;
    padding: 0 20px;
    margin-right: 10px;
  }
}

@media (min-width: $mq-sm) {
  .header {
    &__title {
      font-size: 44px;
      line-height: 54px;
    }

    &__btn {
      font-size: 14px;
      height: 54px;
      line-height: 54px;
      padding: 0 36px;
    }
  }
}

@media (min-width: $mq-md) {
  .header {
    &__container {
      height: 560px;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    &__content {
      margin-left: 0;
    }

    &__title {
      font-size: 34px;
      line-height: 44px;
    }

    &__btn {
      font-size: 12px;
      height: 44px;
      line-height: 44px;
      padding: 0 20px;
    }
  }
}

@media (min-width: $mq-lg) {
  .header {
    &__title {
      font-size: 48px;
      line-height: 58px;
    }

    &__btn {
      font-size: 14px;
      height: 54px;
      line-height: 54px;
      padding: 0 36px;
    }
  }
}