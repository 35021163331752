.alert {
  font-size: 14px;
  text-align: center;
  color: $color-navyblue;
  border-radius: 6px;
  padding: 15px;
  visibility: visible;
  opacity: 1;
  z-index: 5;
  position: fixed;
  bottom: 15px;
  left: 15px;
  right: 15px;

  &--hide {
    animation: hide 7s ease-in forwards;
  }

  &--success {
    background-color: $color-alert-green;
  }

  &--warning {
    background-color: $color-alert-yellow;
  }

  &--danger {
    color: $color-white;
    background-color: $color-alert-red;
  }
}

@media (min-width: $mq-sm) {
  .alert {
    max-width: 400px;
    left: auto;
  }
}

// Hide animation
@keyframes hide {
  80% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}