// All
*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Html
html {
  height: 100%;
}

// Body
body {
  font-family: $font-poppins;
  font-weight: $font-weight-regular;
  color: $color-navyblue;
  background-color: $color-white;
  min-height: 100%;
  margin: 0;
  padding: 0;

  &.scroll-hidden {
    overflow: hidden;
  }
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

// Main
main {
  display: block;
}

// List
ul {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
  }
}

// Link
a {
  text-decoration: none;
}

// Button
button {
  border: 0;
  padding: 0;

  &::-moz-focus-inner {
    border: 0;
  }
}

// Image
img {
  display: block;
}

// Line
hr {
  margin: 0;
  border: 0;
  display: block;
}

// Paragraph
p {
  margin: 0;
}

// Inputs
input,
textarea {
  font-family: $font-poppins;
  border: 0;

  &:required,
  &:invalid {
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }
}