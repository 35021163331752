.toggler {
  width: 46px;
  height: 46px;
  background-color: $color-transparent;
  z-index: 10;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 16px;

  &--toggled {
    .toggler__line {

      &--first {
        transition-delay: .1s;
        transform: translateY(12px) rotate(45deg);
      }

      &--second {
        opacity: 0;
      }

      &--third {
        transition-delay: .1s;
        transform: translateY(-12px) rotate(-45deg);
      }
    }
  }

  &__line {
    width: 100%;
    height: 4px;
    margin: 8px 0;
    background-color: $color-navyblue;
    display: block;
    transition: transform .15s ease, opacity .15s ease;
  }
}

@media (min-width: $mq-lg) {
  .toggler {
    display: none;
  }
}