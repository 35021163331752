*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

body {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 400;
  color: #1f2f3e;
  background-color: #ffffff;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body.scroll-hidden {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

main {
  display: block;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  border: 0;
  padding: 0;
}

button::-moz-focus-inner {
  border: 0;
}

img {
  display: block;
}

hr {
  margin: 0;
  border: 0;
  display: block;
}

p {
  margin: 0;
}

input,
textarea {
  font-family: "Poppins", Arial, sans-serif;
  border: 0;
}

input:required, input:invalid,
textarea:required,
textarea:invalid {
  box-shadow: none;
}

input:focus,
textarea:focus {
  outline: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.btn {
  font-size: 14px;
  font-weight: 600;
  color: #1f2f3e;
  text-transform: uppercase;
  line-height: 44px;
  letter-spacing: 2.4px;
  height: 44px;
  padding: 0 30px;
  border-radius: 40px;
  background-color: #ffffff;
  display: block;
  cursor: pointer;
  transition: background-color .15s ease, color .15s ease;
  outline: 0;
}

.btn:hover, .btn:focus {
  background-color: #eeeeee;
}

.btn--primary {
  color: #ffffff;
  background-color: #1ed760;
}

.btn--primary:hover, .btn--primary:focus {
  background-color: #39e376;
}

.btn--primary:active {
  background-color: #1BC556;
}

.btn--secondary {
  color: #ffffff;
  background-color: #1f2f3e;
}

.btn--secondary:hover, .btn--secondary:focus {
  background-color: #334e67;
}

.btn--secondary:active {
  background-color: #162232;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -10px 35px -10px;
}

.gallery__item {
  width: 100%;
  padding-bottom: 10px;
}

.gallery__link {
  margin: 10px;
  display: block;
  position: relative;
}

.gallery__link:hover .gallery__logo-wrapper, .gallery__link:focus .gallery__logo-wrapper {
  opacity: 1;
}

.gallery__thumbnail {
  height: 300px;
  background-color: #ffffff;
  display: block;
  overflow: hidden;
}

.gallery__logo-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .2s ease;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  opacity: 0;
}

.gallery__logo-wrapper::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: #1f2f3e;
  position: absolute;
  opacity: 1;
  z-index: -1;
  top: 0;
  left: 0;
}

.gallery__logo {
  max-width: 70%;
  max-height: 70%;
  margin: auto;
}

.gallery__image {
  width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (min-width: 480px) {
  .gallery__item {
    width: 50%;
  }
  .gallery__thumbnail {
    height: 280px;
  }
}

@media (min-width: 768px) {
  .gallery {
    margin: -10px 15px 70px 15px;
  }
  .gallery__thumbnail {
    height: 320px;
  }
}

@media (min-width: 992px) {
  .gallery {
    margin: -10px -10px 70px -10px;
  }
  .gallery__item {
    width: 25%;
  }
  .gallery__thumbnail {
    height: 290px;
  }
}

@media (min-width: 1200px) {
  .gallery {
    margin: -10px 15px 70px 15px;
  }
}

.form {
  width: 100%;
}

.form__group {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.form__label {
  font-size: 14px;
  font-weight: 500;
  color: #1ed760;
  line-height: 20px;
  display: table;
  transition: transform .1s ease, font-size .1s ease;
  position: absolute;
  top: 15px;
  left: 0;
}

.form__label--active {
  font-size: 12px;
  transform: translateY(-24px);
}

.form__field {
  font-size: 14px;
  font-weight: 500;
  color: #1f2f3e;
  width: 100%;
  height: 50px;
  background-color: transparent;
  border-bottom: 1px solid #eeeeee;
  transition: border-color .15s ease;
}

.form__field:focus {
  border-color: #1ed760;
}

.form__field--error {
  border-color: #ea2027;
}

.form__field--error:focus {
  border-color: #ea2027;
}

.form__textarea {
  min-height: 120px;
  height: 120px;
  padding: 15px 0;
  resize: vertical;
}

.form__btn {
  font-weight: 500;
  margin-top: 20px;
  margin-left: auto;
  overflow: hidden;
  position: relative;
}

.form__btn::before, .form__btn::after {
  content: '';
  transition: opacity .25s ease;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.form__btn::before {
  width: 30px;
  height: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-right-color: #ffffff;
  z-index: 2;
  border-radius: 50%;
  margin: auto;
}

.form__btn::after {
  background-color: #1ed760;
  z-index: 1;
}

.form__btn--processing::before, .form__btn--processing::after {
  opacity: 1;
}

.form__btn--processing::before {
  -webkit-animation: loading 2s ease-in-out infinite;
  animation: loading 2s ease-in-out infinite;
}

.form__link {
  color: #1ed760;
  outline: none;
  transition: color .15s ease;
}

.form__link:hover, .form__link:focus {
  text-decoration: underline;
}

.form__checkbox:hover, .form__checkbox:focus {
  outline: none;
}

.form__checkbox:hover .form__checkbox-label, .form__checkbox:focus .form__checkbox-label {
  color: #425d77;
}

.form__checkbox .form__error {
  margin-left: 30px;
}

.form__checkbox-input {
  display: none;
}

.form__checkbox-input:checked ~ .form__checkbox-label::after {
  display: block;
}

.form__checkbox-label {
  font-size: 13px;
  font-weight: 500;
  color: #1f2f3e;
  line-height: 20px;
  padding-left: 30px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: color .15s ease;
}

.form__checkbox-label::before, .form__checkbox-label::after {
  content: '';
  position: absolute;
}

.form__checkbox-label::before {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  top: 0;
  left: 0;
}

.form__checkbox-label::after {
  width: 6px;
  height: 10px;
  border-right: 2px solid #1ed760;
  border-bottom: 2px solid #1ed760;
  transform: rotate(45deg);
  top: 4px;
  left: 8px;
  display: none;
}

.form__checkbox-label--error::before {
  border-color: #ea2027;
}

.form__error {
  display: block;
  color: #ea2027;
  font-size: 12px;
  margin-top: 4px;
}

.form__antispam {
  height: 0;
  overflow: hidden;
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1440deg);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1440deg);
  }
}

.alert {
  font-size: 14px;
  text-align: center;
  color: #1f2f3e;
  border-radius: 6px;
  padding: 15px;
  visibility: visible;
  opacity: 1;
  z-index: 5;
  position: fixed;
  bottom: 15px;
  left: 15px;
  right: 15px;
}

.alert--hide {
  -webkit-animation: hide 7s ease-in forwards;
  animation: hide 7s ease-in forwards;
}

.alert--success {
  background-color: #3bd91a;
}

.alert--warning {
  background-color: #fec02c;
}

.alert--danger {
  color: #ffffff;
  background-color: #ea2027;
}

@media (min-width: 480px) {
  .alert {
    max-width: 400px;
    left: auto;
  }
}

@-webkit-keyframes hide {
  80% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes hide {
  80% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.parallax__layer {
  transition: transform .1s ease;
}

.cookie-alert {
  width: 100%;
  min-height: 150px;
  padding: 10px;
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
  z-index: 99;
  display: flex;
  align-items: center;
  position: fixed;
  transition: opacity .15s ease;
  opacity: 1;
  bottom: 0;
  left: 0;
}

.cookie-alert--fade-out {
  opacity: 0;
}

.cookie-alert__image {
  display: none;
}

.cookie-alert__content-wrapper {
  flex: 1;
}

.cookie-alert__heading {
  font-size: 22px;
  font-weight: 700;
  color: #1f2f3e;
  line-height: 22px;
  margin-bottom: 10px;
  display: block;
}

.cookie-alert__highlighted {
  color: #1ed760;
}

.cookie-alert__text {
  font-size: 14px;
  line-height: 24px;
  color: #1f2f3e;
  max-width: 560px;
}

.cookie-alert__actions {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.cookie-alert__about-cookies-btn {
  padding: 0;
  width: 44px;
  text-align: center;
}

.cookie-alert__btn {
  margin-left: 10px;
}

@media (min-width: 768px) {
  .cookie-alert {
    padding: 10px 20px;
  }
  .cookie-alert__image {
    height: 80px;
    display: block;
  }
  .cookie-alert__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cookie-alert__content-wrapper {
    margin-left: 20px;
  }
  .cookie-alert__actions {
    margin-left: 20px;
    margin-top: 0;
  }
}

.nav {
  width: 100%;
  height: 85px;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 10;
  top: 0;
  left: 0;
}

.nav__padding {
  padding-top: 85px;
  height: 1px;
}

.nav__container {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.nav__container::before {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  z-index: 6;
  position: absolute;
  top: 84px;
  left: 0;
}

.nav__brand {
  z-index: 10;
  position: absolute;
  top: 30px;
  left: 20px;
}

.nav__brand-img {
  height: 25px;
}

.nav__list {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 85px 20px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 5;
  transition: opacity .15s ease .1s, visibility .15s ease .1s;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: 0;
  opacity: 0;
}

.nav__list--visible {
  visibility: visible;
  opacity: 1;
}

.nav__item {
  display: block;
  margin: 5px;
}

.nav__link {
  font-size: 18px;
  font-weight: 600;
  color: #1f2f3e;
  line-height: 60px;
  text-align: center;
  height: 60px;
  padding: 0 15px;
  display: block;
  position: relative;
  transition: color .15s ease;
  overflow: hidden;
}

.nav__link::before, .nav__link::after {
  content: '';
  height: 3px;
  background-color: #1ed760;
  position: absolute;
  transition: width .15s ease;
  width: 0;
  bottom: 0;
}

.nav__link::before {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  right: 50%;
}

.nav__link::after {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  left: 50%;
  bottom: 0;
}

.nav__link--active, .nav__link:hover, .nav__link:focus {
  color: #1ed760;
  outline: none;
}

.nav__link--active::before, .nav__link--active::after, .nav__link:hover::before, .nav__link:hover::after, .nav__link:focus::before, .nav__link:focus::after {
  width: 50%;
}

@media (min-width: 992px) {
  .nav__container::before {
    display: none;
  }
  .nav__list {
    height: 100%;
    display: block;
    padding-top: 0;
    position: relative;
    visibility: visible;
    opacity: 1;
    transition: none;
  }
  .nav__item {
    display: inline-block;
    margin: 0 10px;
  }
  .nav__link {
    font-size: 14px;
    line-height: 85px;
    height: 85px;
  }
}

@media (min-width: 1200px) {
  .nav__item {
    margin: 0 15px;
  }
  .nav__link {
    padding: 0 20px;
  }
}

@media (min-width: 1440px) {
  .nav__brand {
    left: 60px;
  }
}

.toggler {
  width: 46px;
  height: 46px;
  background-color: transparent;
  z-index: 10;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 16px;
}

.toggler--toggled .toggler__line--first {
  transition-delay: .1s;
  transform: translateY(12px) rotate(45deg);
}

.toggler--toggled .toggler__line--second {
  opacity: 0;
}

.toggler--toggled .toggler__line--third {
  transition-delay: .1s;
  transform: translateY(-12px) rotate(-45deg);
}

.toggler__line {
  width: 100%;
  height: 4px;
  margin: 8px 0;
  background-color: #1f2f3e;
  display: block;
  transition: transform .15s ease, opacity .15s ease;
}

@media (min-width: 992px) {
  .toggler {
    display: none;
  }
}

.header {
  background-color: #fafafa;
  border-bottom: 1px solid #eeeeee;
  position: relative;
}

.header__container {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  padding: 30px 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
}

.header__overflow-wrapper {
  overflow: hidden;
}

.header__content {
  margin: 50px auto;
  flex-shrink: 0;
}

.header__title {
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  color: #1f2f3e;
  position: relative;
}

.header__title::before {
  content: '';
  width: 50px;
  height: 4px;
  border-radius: 6px;
  background-color: #1ed760;
  position: absolute;
  top: -25px;
  left: 0;
}

.header__text {
  display: block;
}

.header__highlighted {
  color: #1ed760;
}

.header__buttons {
  margin-top: 45px;
  display: flex;
}

.header__btn {
  font-size: 12px;
  padding: 0 20px;
  margin-right: 10px;
}

@media (min-width: 480px) {
  .header__title {
    font-size: 44px;
    line-height: 54px;
  }
  .header__btn {
    font-size: 14px;
    height: 54px;
    line-height: 54px;
    padding: 0 36px;
  }
}

@media (min-width: 768px) {
  .header__container {
    height: 560px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  .header__content {
    margin-left: 0;
  }
  .header__title {
    font-size: 34px;
    line-height: 44px;
  }
  .header__btn {
    font-size: 12px;
    height: 44px;
    line-height: 44px;
    padding: 0 20px;
  }
}

@media (min-width: 992px) {
  .header__title {
    font-size: 48px;
    line-height: 58px;
  }
  .header__btn {
    font-size: 14px;
    height: 54px;
    line-height: 54px;
    padding: 0 36px;
  }
}

.cloud {
  z-index: 1;
  width: 100%;
  margin: 100px 0;
  font-size: 20px;
  display: none;
}

.cloud__image-wrapper {
  width: 100%;
  max-width: 460px;
  min-height: 100px;
  margin: 0 auto;
  position: relative;
}

.cloud__tags-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cloud__image {
  z-index: 2;
  width: 100%;
  flex-shrink: 0;
}

.cloud__tag {
  font-weight: 700;
  color: #1f2f3e;
  text-transform: uppercase;
  display: block;
  white-space: nowrap;
  position: absolute;
}

.cloud__tag--one {
  font-size: 1.2em;
  top: -29.26829%;
  left: 3.26087%;
}

.cloud__tag--two {
  font-size: 0.8em;
  color: transparent;
  text-shadow: 0 0 4px #1f2f3e;
  top: -14.63415%;
  right: 10.86957%;
}

.cloud__tag--three {
  font-size: 1.2em;
  z-index: -1;
  top: 14.63415%;
  right: -21.73913%;
}

.cloud__tag--four {
  font-size: 0.80em;
  z-index: 2;
  bottom: 8.78049%;
  right: -25%;
}

.cloud__tag--five {
  font-size: 1.2em;
  bottom: -32.68293%;
  right: -4.34783%;
}

.cloud__tag--six {
  font-size: 1.9em;
  z-index: -1;
  bottom: -11.70732%;
  left: -30.43478%;
}

.cloud__tag--seven {
  font-size: 1.15em;
  color: transparent;
  z-index: 2;
  text-shadow: 0 0 4px #1f2f3e;
  top: 32.19512%;
  left: -15.21739%;
}

@media (min-width: 480px) {
  .cloud {
    font-size: 24px;
  }
  .cloud__image-wrapper {
    min-height: 200px;
  }
}

@media (min-width: 768px) {
  .cloud {
    margin-left: 100px;
    font-size: 18px;
    display: block;
  }
  .cloud__image-wrapper {
    max-width: 340px;
    min-height: 140px;
    margin-right: 0;
  }
  .cloud__tag--four {
    right: -30.43478%;
  }
  .cloud__tag--six {
    left: -22.82609%;
  }
}

@media (min-width: 992px) {
  .cloud {
    font-size: 24px;
    margin-left: 120px;
  }
  .cloud__image-wrapper {
    max-width: 460px;
    min-height: 180px;
  }
  .cloud__tag--four {
    right: -33.69565%;
  }
  .cloud__tag--six {
    left: -30.43478%;
  }
}

@media (min-width: 1200px) {
  .cloud {
    font-size: 26px;
  }
  .cloud__image-wrapper {
    min-height: 200px;
  }
  .cloud__tag--four {
    right: -26.08696%;
  }
}

.scroll {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1ed760;
  z-index: 2;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25px;
}

.scroll::before, .scroll::after {
  content: '';
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  background-color: #1ed760;
  opacity: .6;
  position: absolute;
  left: 50%;
  top: 50%;
}

.scroll::before {
  width: 80px;
  height: 80px;
  z-index: -1;
  -webkit-animation: 2s 2s infinite pulse;
  animation: 2s 2s infinite pulse;
}

.scroll::after {
  width: 110px;
  height: 110px;
  z-index: 3;
  -webkit-animation: 2s infinite pulse;
  animation: 2s infinite pulse;
}

.scroll__link {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  position: relative;
}

.scroll__mouse {
  width: 20px;
  height: 30px;
  border-radius: 40px;
  background-color: transparent;
  border: 2px solid #ffffff;
  position: relative;
}

.scroll__mouse::before {
  content: '';
  width: 6px;
  height: 6px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  transform: translateX(-50%);
  top: 6px;
  left: 50%;
  -webkit-animation: 2s .1s infinite mouse-move;
  animation: 2s .1s infinite mouse-move;
}

@-webkit-keyframes mouse-move {
  0% {
    transform: translate(-50%, 0);
    opacity: 0;
  }
  20%,
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 10px);
    opacity: 0;
  }
}

@keyframes mouse-move {
  0% {
    transform: translate(-50%, 0);
    opacity: 0;
  }
  20%,
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 10px);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.4);
    opacity: .6;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.4);
    opacity: .6;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.section {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1200px;
}

.section__heading {
  font-size: 26px;
  font-weight: 700;
  line-height: 26px;
  color: #1f2f3e;
  text-transform: uppercase;
}

.section__subheading {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #1ed760;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 8px;
}

.section__divider {
  width: 50px;
  height: 4px;
  background-color: #1ed760;
  border-radius: 6px;
  margin: 25px 0;
}

.section__divider--centered {
  margin: 25px auto;
}

.section__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: #1f2f3e;
}

@media (min-width: 480px) {
  .section__heading {
    font-size: 36px;
    line-height: 36px;
  }
}

.about-us {
  margin-top: 20px;
  padding-top: 80px;
  padding-bottom: 45px;
}

.about-us__image {
  width: 100%;
  max-width: 550px;
  margin: 0 auto 50px auto;
}

.about-us__content {
  flex: 1;
}

.about-us__text {
  text-align: justify;
}

@media (min-width: 768px) {
  .about-us {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-bottom: 80px;
  }
  .about-us__image {
    width: 50%;
    margin: 0 0 0 40px;
    flex-shrink: 0;
  }
}

.offer {
  margin: -10px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.offer__card {
  width: 100%;
  display: flex;
}

.offer__content {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 40px 20px;
  margin: 10px;
  display: flex;
  align-items: center;
  flex: 1;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.offer__content::before {
  content: '';
  width: 120%;
  height: 100%;
  background-color: #1ed760;
  transform: skewX(-10deg) translateX(-120%);
  z-index: -1;
  transition: transform .3s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.offer__content:hover::before {
  transform: skewX(-10deg) translateX(-10%);
}

.offer__content:hover .offer__heading {
  color: #ffffff;
}

.offer__content:hover .offer__image path {
  fill: #ffffff;
}

.offer__heading {
  font-size: 16px;
  color: #1f2f3e;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color .3s ease;
}

.offer__image {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  flex-shrink: 0;
}

.offer__image path {
  transition: fill .3s ease;
}

@media (min-width: 480px) {
  .offer__card {
    width: 50%;
  }
  .offer__heading {
    font-size: 13px;
  }
  .offer__image {
    margin-right: 15px;
  }
}

@media (min-width: 768px) {
  .offer__content {
    padding: 60px 50px;
  }
  .offer__heading {
    font-size: 16px;
  }
  .offer__image {
    width: 45px;
    height: 45px;
    margin-right: 30px;
  }
}

@media (min-width: 992px) {
  .offer__card {
    width: 33.33333%;
  }
  .offer__content {
    padding: 60px 40px;
  }
}

@media (min-width: 1200px) {
  .offer__content {
    padding: 70px 60px;
  }
  .offer__heading {
    font-size: 18px;
  }
  .offer__image {
    margin-right: 32px;
  }
}

.our-strengths {
  padding-top: 45px;
  padding-bottom: 45px;
}

.our-strengths__heading {
  text-align: right;
}

.our-strengths__subheading {
  text-align: right;
}

.our-strengths__divider {
  margin-left: auto;
}

.our-strengths__image {
  width: 100%;
  max-width: 550px;
  margin: 0 auto 50px auto;
  flex-shrink: 0;
}

.our-strengths__content {
  flex: 1;
}

.our-strengths__text {
  text-align: justify;
}

@media (min-width: 768px) {
  .our-strengths {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .our-strengths__image {
    width: 50%;
    margin: 0 40px 0 0;
  }
}

.why-us {
  padding-bottom: 45px;
}

.why-us__panel {
  border: 1px solid #eeeeee;
  position: relative;
}

.why-us__heading {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 2px;
  background-color: #ffffff;
  padding: 0 18px;
  display: table;
  z-index: 2;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -9px;
}

.why-us__grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.why-us__card {
  width: 50%;
  display: flex;
}

.why-us__card-content {
  width: 100%;
  max-width: 250px;
  padding: 35px 15px 50px 15px;
  margin: 0 auto;
  position: relative;
}

.why-us__card-content::after {
  content: '';
  width: 100%;
  height: 4px;
  border-radius: 6px;
  background-color: #1ed760;
  position: absolute;
  transition: opacity .15s ease;
  opacity: 0;
  bottom: 0;
  left: 0;
}

.why-us__card-content:hover .why-us__card-heading {
  color: #1ed760;
}

.why-us__card-content:hover::after {
  opacity: 1;
}

.why-us__image {
  text-align: center;
  height: 55px;
  margin: 0 auto 22px auto;
}

.why-us__card-heading {
  font-size: 15px;
  line-height: 22px;
  color: #1f2f3e;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: color .15s ease;
}

@media (min-width: 480px) {
  .why-us__card-heading {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .why-us {
    padding-bottom: 80px;
  }
  .why-us__card {
    width: 25%;
  }
}

@media (min-width: 992px) {
  .why-us__card-heading {
    font-size: 18px;
  }
}

.portfolio {
  z-index: 1;
  padding-bottom: 60px;
}

.portfolio__bg {
  position: relative;
}

.portfolio__overlay {
  height: 480px;
  background-color: #1ed760;
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.portfolio__content {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1200px;
}

.portfolio__heading {
  color: #ffffff;
  text-align: center;
  padding-top: 50px;
}

.portfolio__subheading {
  color: #ffffff;
  text-align: center;
}

.portfolio__divider {
  background-color: #ffffff;
  margin: 25px auto;
}

.portfolio__filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 35px 0;
}

.portfolio__item {
  font-size: 16px;
  color: #ffffff;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 5px 10px;
  margin: 5px 10px;
  cursor: pointer;
  transition: color .15s ease;
  background: none;
  border: 0;
}

.portfolio__item:hover, .portfolio__item:focus {
  color: #1f2f3e;
  outline: none;
}

.portfolio__item--active {
  font-weight: 700;
}

.portfolio__more {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 16px;
  text-align: center;
  letter-spacing: 2px;
  display: block;
  margin-bottom: 45px;
}

.portfolio__buttons {
  text-align: center;
}

.portfolio__btn {
  height: 50px;
  line-height: 50px;
  margin: 5px 10px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  padding-left: 0;
}

.portfolio__btn:hover.behance-btn, .portfolio__btn:focus.behance-btn {
  background-color: #0056ff;
}

.portfolio__btn:hover.behance-btn svg > path, .portfolio__btn:focus.behance-btn svg > path {
  fill: #0056ff;
}

.portfolio__btn:hover.dribbble-btn, .portfolio__btn:focus.dribbble-btn {
  background-color: #ec498a;
}

.portfolio__btn:hover.dribbble-btn svg > path, .portfolio__btn:focus.dribbble-btn svg > path {
  fill: #ec498a;
}

.portfolio__btn-icon {
  width: 42px;
  height: 42px;
  background-color: #ffffff;
  border-radius: 50%;
  margin: 4px 15px 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio__btn-icon svg > path {
  transition: fill .15s ease;
}

@media (min-width: 400px) {
  .portfolio__buttons {
    display: flex;
    justify-content: center;
  }
  .portfolio__btn {
    margin: 0 10px;
  }
}

@media (min-width: 480px) {
  .portfolio__btn {
    height: 55px;
    line-height: 55px;
  }
  .portfolio__btn-icon {
    width: 47px;
    height: 47px;
  }
}

@media (min-width: 768px) {
  .portfolio__overlay {
    height: 380px;
  }
  .portfolio__item {
    font-size: 14px;
    line-height: 14px;
  }
}

@media (min-width: 992px) {
  .portfolio {
    padding-bottom: 80px;
  }
  .portfolio__overlay {
    height: 350px;
  }
}

.contact {
  border-top: 1px solid #eeeeee;
  background-color: #fafafa;
}

.contact__content {
  margin: 0 auto;
  padding: 0 20px 50px 20px;
  width: 100%;
  max-width: 1200px;
}

.contact__image {
  width: 100%;
  max-width: 550px;
  margin: 45px auto 80px auto;
}

.contact__heading {
  padding-top: 50px;
  text-align: center;
}

.contact__subheading {
  text-align: center;
}

.contact__divider {
  margin: 25px auto;
}

@media (min-width: 768px) {
  .contact__form-content {
    display: flex;
    align-items: center;
  }
  .contact__image {
    width: 50%;
    flex-shrink: 0;
    margin: 0 50px 0 0;
  }
}

@media (min-width: 992px) {
  .contact__image {
    margin: 0 80px 0 0;
  }
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page__section {
  flex: 1;
}

.page__header {
  text-align: center;
  margin-top: 100px;
}

.page__content {
  margin: 60px 0 100px 0;
}

.page__content h2,
.page__content h3,
.page__content h4,
.page__content h5 {
  font-size: 22px;
  margin-bottom: 10px;
}

.page__content ol {
  padding-left: 30px;
}

.page__content ul {
  padding-left: 30px;
}

.page__content ul > li {
  list-style-type: disc;
}

.page__content a {
  color: #1ed760;
  transition: color .15s ease;
}

.page__content a:focus, .page__content a:hover {
  color: #1f2f3e;
}

.page__content .text-center {
  text-align: center;
}

.page__content .text-right {
  text-align: right;
}

.portfolio-item__subheading {
  font-size: 18px;
}

.portfolio-item__image-wrapper {
  max-width: 1000px;
  margin: 0 auto 20px auto;
}

.portfolio-item__image {
  max-width: 100%;
  margin: 0 auto;
}

.p404__image-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.p404__right-col {
  background-color: #1ed760;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.p404__image {
  width: 100%;
  max-height: 470px;
  margin: 100px 20px;
}

.p404__text-wrapper {
  width: 100%;
  margin: 80px 20px;
}

.p404__heading {
  font-size: 32px;
  color: #ffffff;
  line-height: 42px;
  max-width: 600px;
}

.p404__text {
  color: #1f2f3e;
}

.p404__brand-img {
  height: 25px;
  margin-bottom: 36px;
  display: block;
}

.p404__brand-img svg {
  height: 25px;
}

.p404__brand-img svg path,
.p404__brand-img svg rect {
  fill: #ffffff;
}

.p404__btn {
  text-align: center;
  line-height: 55px;
  width: 220px;
  height: 55px;
  margin-top: 25px;
  max-width: 220px;
}

@media (min-width: 480px) {
  .p404__heading {
    font-size: 36px;
  }
}

@media (min-width: 768px) {
  .p404__image {
    margin: 100px 45px;
  }
  .p404__text-wrapper {
    margin: 100px 45px;
  }
}

@media (min-width: 992px) {
  .p404-wrapper {
    min-height: 600px;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .p404 {
    display: flex;
    flex: 1;
  }
  .p404__right-col {
    width: 450px;
  }
}

@media (min-width: 1200px) {
  .p404__right-col {
    width: 600px;
  }
}

@media (min-width: 1440px) {
  .p404__right-col {
    width: 680px;
  }
  .p404__text-wrapper {
    margin: 0 90px;
  }
  .p404__image {
    max-height: 430px;
    margin: 0 80px;
  }
}

.footer {
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
}

.footer__url {
  color: #1f2f3e;
}

.footer__copyright {
  padding: 40px 0 30px 0;
  background-color: #1ed760;
}

.footer__content {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.footer__brand {
  display: table;
  margin: 0 auto;
}

.footer__brand-img {
  height: 25px;
  text-align: center;
  display: block;
  margin-bottom: 15px;
}

.footer__brand-img svg {
  height: 25px;
}

.footer__brand-img svg path,
.footer__brand-img svg rect {
  fill: #ffffff;
}

.footer__text {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  display: block;
}

.footer__powered-by {
  margin-top: 5px;
  font-size: 10px;
}

.footer__powered-by a {
  color: #ffffff;
}

@media (min-width: 768px) {
  .footer__contact-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 45px;
  }
}

.p404-footer__content {
  flex: 1;
  padding: 0 45px;
}

.p404-footer__copyright {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  width: 100%;
}

.p404-footer__text {
  color: #1f2f3e;
}

.p404-footer__powered-by a {
  color: #1f2f3e;
}

@media (min-width: 992px) {
  .p404-footer {
    display: flex;
  }
  .p404-footer__content {
    display: block;
    height: 145px;
  }
  .p404-footer__copyright {
    background-color: #1ed760;
    width: 450px;
    padding: 0;
  }
  .p404-footer__text {
    color: #ffffff;
  }
  .p404-footer__powered-by a {
    color: #ffffff;
  }
}

@media (min-width: 1200px) {
  .p404-footer__copyright {
    width: 600px;
  }
}

@media (min-width: 1440px) {
  .p404-footer__copyright {
    width: 680px;
  }
  .p404-footer__content {
    display: flex;
    height: 85px;
  }
}

.contact-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 0;
}

.contact-list__item {
  font-size: 14px;
  font-weight: 700;
  color: #1f2f3e;
  letter-spacing: 1px;
  line-height: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin: 10px 30px;
}

.contact-list__icon {
  display: block;
  margin-right: 25px;
}

@media (min-width: 768px) {
  .contact-list {
    margin: 26px 0;
  }
  .contact-list__item {
    margin: 0;
  }
  .contact-list__item:first-child {
    margin-right: 50px;
  }
}

.social-media {
  display: flex;
  justify-content: center;
  margin: 0 auto 20px auto;
}

.social-media__icon {
  margin: 0 10px;
}

.social-media__url {
  padding: 15px;
  display: flex;
  align-items: center;
}

.social-media__url svg {
  height: 20px;
}

.social-media__url svg path {
  transition: fill .15s ease;
  fill: #1f2f3e;
}

.social-media__url:hover svg path, .social-media__url:focus svg path {
  fill: #1ed760;
}

@media (min-width: 768px) {
  .social-media {
    margin: 0;
  }
  .social-media__icon:last-child {
    margin-right: -5px;
  }
  .social-media__url {
    padding: 5px;
  }
}
