.social-media {
  display: flex;
  justify-content: center;
  margin: 0 auto 20px auto;

  &__icon {
    margin: 0 10px;
  }

  &__url {
    padding: 15px;
    display: flex;
    align-items: center;

    svg {
      height: 20px;

      path {
        transition: fill .15s ease;
        fill: $color-navyblue;
      }
    }

    &:hover,
    &:focus {
      svg path {
        fill: $color-green;
      }
    }
  }
}

@media (min-width: $mq-md) {
  .social-media {
    margin: 0;

    &__icon {
      &:last-child {
        margin-right: -5px;
      }
    }

    &__url {
      padding: 5px;
    }
  }
}