$image-width: 460px;
$image-height: 205px;

.cloud {
  z-index: 1;
  width: 100%;
  margin: 100px 0;
  font-size: 20px;
  display: none;

  &__image-wrapper {
    width: 100%;
    max-width: 460px;
    min-height: 100px;
    margin: 0 auto;
    position: relative;
  }

  &__tags-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__image {
    z-index: 2;
    width: 100%;
    flex-shrink: 0;
  }

  &__tag {
    font-weight: $font-weight-bold;
    color: $color-navyblue;
    text-transform: uppercase;
    display: block;
    white-space: nowrap;
    position: absolute;

    &--one {
      font-size: 1.2em;
      top: percent-of-number($image-height, -60px);
      left: percent-of-number($image-width, 15px);
    }

    &--two {
      font-size: 0.8em;
      color: $color-transparent;
      text-shadow: 0 0 4px $color-navyblue;
      top: percent-of-number($image-height, -30px);
      right: percent-of-number($image-width, 50px);
    }

    &--three {
      font-size: 1.2em;
      z-index: -1;
      top: percent-of-number($image-height, 30px);
      right: percent-of-number($image-width, -100px);
    }

    &--four {
      font-size: 0.80em;
      z-index: 2;
      bottom: percent-of-number($image-height, 18px);
      right: percent-of-number($image-width, -115px);
    }

    &--five {
      font-size: 1.2em;
      bottom: percent-of-number($image-height, -67px);
      right: percent-of-number($image-width, -20px);
    }

    &--six {
      font-size: 1.9em;
      z-index: -1;
      bottom: percent-of-number($image-height, -24px);
      left: percent-of-number($image-width, -140px);
    }

    &--seven {
      font-size: 1.15em;
      color: $color-transparent;
      z-index: 2;
      text-shadow: 0 0 4px $color-navyblue;
      top: percent-of-number($image-height, 66px);
      left: percent-of-number($image-width, -70px);
    }
  }
}

@media (min-width: $mq-sm) {
  .cloud {
    font-size: 24px;

    &__image-wrapper {
      min-height: 200px;
    }
  }
}

@media (min-width: $mq-md) {
  .cloud {
    margin-left: 100px;
    font-size: 18px;
    display: block;

    &__image-wrapper {
      max-width: 340px;
      min-height: 140px;
      margin-right: 0;
    }

    &__tag {
      &--four {
        right: percent-of-number($image-width, -140px);
      }

      &--six {
        left: percent-of-number($image-width, -105px);
      }
    }
  }
}

@media (min-width: $mq-lg) {
  .cloud {
    font-size: 24px;
    margin-left: 120px;

    &__image-wrapper {
      max-width: 460px;
      min-height: 180px;
    }

    &__tag {
      &--four {
        right: percent-of-number($image-width, -155px);
      }

      &--six {
        left: percent-of-number($image-width, -140px);
      }
    }
  }
}

@media (min-width: $mq-xlg) {
  .cloud {
    font-size: 26px;

    &__image-wrapper {
      min-height: 200px;
    }

    &__tag {
      &--four {
        right: percent-of-number($image-width, -120px);
      }
    }
  }
}