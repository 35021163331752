.form {
  width: 100%;

  &__group {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }

  &__label {
    font-size: 14px;
    font-weight: $font-weight-medium;
    color: $color-green;
    line-height: 20px;
    display: table;
    transition: transform .1s ease, font-size .1s ease;
    position: absolute;
    top: 15px;
    left: 0;

    &--active {
      font-size: 12px;
      transform: translateY(-24px);
    }
  }

  &__field {
    font-size: 14px;
    font-weight: $font-weight-medium;
    color: $color-navyblue;
    width: 100%;
    height: 50px;
    background-color: $color-transparent;
    border-bottom: 1px solid $color-grey-bright;
    transition: border-color .15s ease;

    &:focus {
      border-color: $color-green;
    }

    &--error {
      border-color: $color-red;

      &:focus {
        border-color: $color-red;
      }
    }
  }

  &__textarea {
    min-height: 120px;
    height: 120px;
    padding: 15px 0;
    resize: vertical;
  }

  &__btn {
    font-weight: $font-weight-medium;
    margin-top: 20px;
    margin-left: auto;
    overflow: hidden;
    position: relative;

    &::before,
    &::after {
      content: '';
      transition: opacity .25s ease;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }

    &::before {
      width: 30px;
      height: 30px;
      border-width: 2px;
      border-style: solid;
      border-color: $color-transparent;
      border-right-color: $color-white;
      z-index: 2;
      border-radius: 50%;
      margin: auto;
    }

    &::after {
      background-color: $color-green;
      z-index: 1;
    }

    &--processing {
      &::before,
      &::after {
        opacity: 1;
      }

      &::before {
        animation: loading 2s ease-in-out infinite;
      }
    }
  }

  &__link {
    color: $color-green;
    outline: none;
    transition: color .15s ease;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__checkbox {
    &:hover,
    &:focus {
      outline: none;

      .form__checkbox-label {
        color: $color-navyblue-bright2;
      }
    }

    .form__error {
      margin-left: 30px;
    }
  }

  &__checkbox-input {
    display: none;

    &:checked {
      & ~ .form__checkbox-label::after {
        display: block;
      }
    }
  }

  &__checkbox-label {
    font-size: 13px;
    font-weight: $font-weight-medium;
    color: $color-navyblue;
    line-height: 20px;
    padding-left: 30px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    transition: color .15s ease;

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      width: 20px;
      height: 20px;
      border-radius: 6px;
      background-color: $color-white;
      border: 1px solid $color-grey-bright;
      top: 0;
      left: 0;
    }

    &::after {
      width: 6px;
      height: 10px;
      border-right: 2px solid $color-green;
      border-bottom: 2px solid $color-green;
      transform: rotate(45deg);
      top: 4px;
      left: 8px;
      display: none;
    }

    &--error {
      &::before {
        border-color: $color-red;
      }
    }
  }

  &__error {
    display: block;
    color: $color-red;
    font-size: 12px;
    margin-top: 4px;
  }

  &__antispam {
    height: 0;
    overflow: hidden;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1440deg);
  }
}