.contact-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 0;

  &__item {
    font-size: 14px;
    font-weight: $font-weight-bold;
    color: $color-navyblue;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin: 10px 30px;
  }

  &__icon {
    display: block;
    margin-right: 25px;
  }
}

@media (min-width: $mq-md) {
  .contact-list {
    margin: 26px 0;

    &__item {
      margin: 0;

      &:first-child {
        margin-right: 50px;
      }
    }
  }
}