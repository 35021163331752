.portfolio {
  z-index: 1;
  padding-bottom: 60px;

  &__bg {
    position: relative;
  }

  &__overlay {
    height: 480px;
    background-color: $color-green;
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  &__content {
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    max-width: $layout-width;
  }

  &__heading {
    color: $color-white;
    text-align: center;
    padding-top: 50px;
  }

  &__subheading {
    color: $color-white;
    text-align: center;
  }

  &__divider {
    background-color: $color-white;
    margin: 25px auto;
  }

  &__filter {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 35px 0;
  }

  &__item {
    font-size: 16px;
    color: $color-white;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 5px 10px;
    margin: 5px 10px;
    cursor: pointer;
    transition: color .15s ease;
    background: none;
    border: 0;

    &:hover,
    &:focus {
      color: $color-navyblue;
      outline: none;
    }

    &--active {
      font-weight: $font-weight-bold;
    }
  }

  &__more {
    font-size: 16px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    line-height: 16px;
    text-align: center;
    letter-spacing: 2px;
    display: block;
    margin-bottom: 45px;
  }

  &__buttons {
    text-align: center;
  }

  &__btn {
    height: 50px;
    line-height: 50px;
    margin: 5px 10px;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    padding-left: 0;

    &:hover,
    &:focus {
      &.behance-btn {
        background-color: $color-blue;

        svg > path {
          fill: $color-blue;
        }
      }

      &.dribbble-btn {
        background-color: $color-pink;

        svg > path {
          fill: $color-pink;
        }
      }
    }
  }

  &__btn-icon {
    width: 42px;
    height: 42px;
    background-color: $color-white;
    border-radius: 50%;
    margin: 4px 15px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg > path {
      transition: fill .15s ease;
    }
  }
}

@media (min-width: $mq-xsm) {
  .portfolio {
    &__buttons {
      display: flex;
      justify-content: center;
    }

    &__btn {
      margin: 0 10px;
    }
  }
}

@media (min-width: $mq-sm) {
  .portfolio {
    &__btn {
      height: 55px;
      line-height: 55px;
    }

    &__btn-icon {
      width: 47px;
      height: 47px;
    }
  }
}

@media (min-width: $mq-md) {
  .portfolio {
    &__overlay {
      height: 380px;
    }

    &__item {
      font-size: 14px;
      line-height: 14px;
    }
  }
}

@media (min-width: $mq-lg) {
  .portfolio {
    padding-bottom: 80px;

    &__overlay {
      height: 350px;
    }
  }
}