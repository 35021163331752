.p404 {
  &__image-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  &__right-col {
    background-color: $color-green;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__image {
    width: 100%;
    max-height: 470px;
    margin: 100px 20px;
  }

  &__text-wrapper {
    width: 100%;
    margin: 80px 20px;
  }

  &__heading {
    font-size: 32px;
    color: $color-white;
    line-height: 42px;
    max-width: 600px;
  }

  &__text {
    color: $color-navyblue;
  }

  &__brand-img {
    height: 25px;
    margin-bottom: 36px;
    display: block;

    svg {
      height: 25px;

      path,
      rect {
        fill: $color-white;
      }
    }
  }

  &__btn {
    text-align: center;
    line-height: 55px;
    width: 220px;
    height: 55px;
    margin-top: 25px;
    max-width: 220px;
  }
}

@media (min-width: $mq-sm) {
  .p404 {
    &__heading {
      font-size: 36px;
    }
  }
}

@media (min-width: $mq-md) {
  .p404 {
    &__image {
      margin: 100px 45px;
    }

    &__text-wrapper {
      margin: 100px 45px;
    }
  }
}

@media (min-width: $mq-lg) {
  .p404-wrapper {
    min-height: 600px;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .p404 {
    display: flex;
    flex: 1;

    &__right-col {
      width: 450px;
    }
  }
}

@media (min-width: $mq-xlg) {
  .p404 {
    &__right-col {
      width: 600px;
    }
  }
}

@media (min-width: $mq-xxlg) {
  .p404 {
    &__right-col {
      width: 680px;
    }

    &__text-wrapper {
      margin: 0 90px;
    }

    &__image {
      max-height: 430px;
      margin: 0 80px;
    }
  }
}