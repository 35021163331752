.contact {
  border-top: 1px solid $color-grey-bright;
  background-color: $color-white-dark;

  &__content {
    margin: 0 auto;
    padding: 0 20px 50px 20px;
    width: 100%;
    max-width: $layout-width;
  }

  &__image {
    width: 100%;
    max-width: 550px;
    margin: 45px auto 80px auto;
  }

  &__heading {
    padding-top: 50px;
    text-align: center;
  }

  &__subheading {
    text-align: center;
  }

  &__divider {
    margin: 25px auto;
  }
}

@media (min-width: $mq-md) {
  .contact {
    &__form-content {
      display: flex;
      align-items: center;
    }

    &__image {
      width: 50%;
      flex-shrink: 0;
      margin: 0 50px 0 0;
    }
  }
}

@media (min-width: $mq-lg) {
  .contact {
    &__image {
      margin: 0 80px 0 0;
    }
  }
}