.portfolio-item {
  &__subheading {
    font-size: 18px;
  }

  &__image-wrapper {
    max-width: 1000px;
    margin: 0 auto 20px auto;
  }

  &__image {
    max-width: 100%;
    margin: 0 auto;
  }
}