.our-strengths {
  padding-top: 45px;
  padding-bottom: 45px;

  &__heading {
    text-align: right;
  }

  &__subheading {
    text-align: right;
  }


  &__divider {
    margin-left: auto;
  }

  &__image {
    width: 100%;
    max-width: 550px;
    margin: 0 auto 50px auto;
    flex-shrink: 0;
  }

  &__content {
    flex: 1;
  }

  &__text {
    text-align: justify;
  }
}

@media (min-width: $mq-md) {
  .our-strengths {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-top: 80px;
    padding-bottom: 80px;

    &__image {
      width: 50%;
      margin: 0 40px 0 0;
    }
  }
}