.btn {
  font-size: 14px;
  font-weight: $font-weight-semibold;
  color: $color-navyblue;
  text-transform: uppercase;
  line-height: 44px;
  letter-spacing: 2.4px;
  height: 44px;
  padding: 0 30px;
  border-radius: 40px;
  background-color: $color-white;
  display: block;
  cursor: pointer;
  transition: background-color .15s ease, color .15s ease;
  outline: 0;

  &:hover,
  &:focus {
    background-color: $color-grey-bright;
  }

  &--primary {
    color: $color-white;
    background-color: $color-green;

    &:hover,
    &:focus {
      background-color: $color-green-bright;
    }

    &:active {
      background-color:$color-green-dark;
    }
  }

  &--secondary {
    color: $color-white;
    background-color: $color-navyblue;

    &:hover,
    &:focus {
      background-color: $color-navyblue-bright;
    }

    &:active {
      background-color: $color-navyblue-dark;
    }
  }
}