.cookie-alert {
  width: 100%;
  min-height: 150px;
  padding: 10px;
  background-color: $color-white;
  border-top: 1px solid $color-grey-bright;
  z-index: 99;
  display: flex;
  align-items: center;
  position: fixed;
  transition: opacity .15s ease;
  opacity: 1;
  bottom: 0;
  left: 0;

  &--fade-out {
    opacity: 0;
  }

  &__image {
    display: none;
  }

  &__content-wrapper {
    flex: 1;
  }

  &__heading {
    font-size: 22px;
    font-weight: $font-weight-bold;
    color: $color-navyblue;
    line-height: 22px;
    margin-bottom: 10px;
    display: block;
  }

  &__highlighted {
    color: $color-green;
  }

  &__text {
    font-size: 14px;
    line-height: 24px;
    color: $color-navyblue;
    max-width: 560px;
  }

  &__actions {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  &__about-cookies-btn {
    padding: 0;
    width: 44px;
    text-align: center;
  }

  &__btn {
    margin-left: 10px;
  }
}

@media (min-width: $mq-md) {
  .cookie-alert {
    padding: 10px 20px;

    &__image {
      height: 80px;
      display: block;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__content-wrapper {
      margin-left: 20px;
    }

    &__actions {
      margin-left: 20px;
      margin-top: 0;
    }
  }
}