.why-us {
  padding-bottom: 45px;

  &__panel {
    border: 1px solid $color-grey-bright;
    position: relative;
  }

  &__heading {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 2px;
    background-color: $color-white;
    padding: 0 18px;
    display: table;
    z-index: 2;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -9px;
  }

  &__grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
  }

  &__card {
    width: 50%;
    display: flex;
  }

  &__card-content {
    width: 100%;
    max-width: 250px;
    padding: 35px 15px 50px 15px;
    margin: 0 auto;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 4px;
      border-radius: 6px;
      background-color: $color-green;
      position: absolute;
      transition: opacity .15s ease;
      opacity: 0;
      bottom: 0;
      left: 0;
    }

    &:hover {
      .why-us__card-heading {
        color: $color-green;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  &__image {
    text-align: center;
    height: 55px;
    margin: 0 auto 22px auto;
  }

  &__card-heading {
    font-size: 15px;
    line-height: 22px;
    color: $color-navyblue;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: color .15s ease;
  }
}

@media (min-width: $mq-sm) {
  .why-us {
    &__card-heading {
      font-size: 16px;
    }
  }
}

@media (min-width: $mq-md) {
  .why-us {
    padding-bottom: 80px;

    &__card {
      width: 25%;
    }
  }
}

@media (min-width: $mq-lg) {
  .why-us {
    &__card-heading {
      font-size: 18px;
    }
  }
}