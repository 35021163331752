.nav {
  width: 100%;
  height: 85px;
  background-color: $color-white;
  border-bottom: 1px solid $color-grey-bright;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 10;
  top: 0;
  left: 0;

  &__padding {
    padding-top: 85px;
    height: 1px;
  }

  &__container {
    width: 100%;
    max-width: $layout-width;
    height: 100%;
    margin: 0 auto;
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $color-grey-bright;
      z-index: 6;
      position: absolute;
      top: 84px;
      left: 0;
    }
  }

  &__brand {
    z-index: 10;
    position: absolute;
    top: 30px;
    left: 20px;
  }

  &__brand-img {
    height: 25px;
  }

  &__list {
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    padding: 85px 20px 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    transition: opacity .15s ease .1s, visibility .15s ease .1s;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: 0;
    opacity: 0;

    &--visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &__item {
    display: block;
    margin: 5px;
  }

  &__link {
    font-size: 18px;
    font-weight: $font-weight-semibold;
    color: $color-navyblue;
    line-height: 60px;
    text-align: center;
    height: 60px;
    padding: 0 15px;
    display: block;
    position: relative;
    transition: color .15s ease;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      height: 3px;
      background-color: $color-green;
      position: absolute;
      transition: width .15s ease;
      width: 0;
      bottom: 0;
    }

    &::before {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      right: 50%;
    }

    &::after {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      left: 50%;
      bottom: 0;
    }

    &--active,
    &:hover,
    &:focus {
      color: $color-green;
      outline: none;

      &::before,
      &::after {
        width: 50%;
      }
    }
  }
}

@media (min-width: $mq-lg) {
  .nav {
    &__container {
      &::before {
        display: none;
      }
    }

    &__list {
      height: 100%;
      display: block;
      padding-top: 0;
      position: relative;
      visibility: visible;
      opacity: 1;
      transition: none;
    }

    &__item {
      display: inline-block;
      margin: 0 10px;
    }

    &__link {
      font-size: 14px;
      line-height: 85px;
      height: 85px;
    }
  }
}

@media (min-width: $mq-xlg) {
  .nav {
    &__item {
      margin: 0 15px;
    }

    &__link {
      padding: 0 20px;
    }
  }
}

@media (min-width: $mq-xxlg) {
  .nav {
    &__brand {
      left: 60px;
    }
  }
}