.section {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: $layout-width;

  &__heading {
    font-size: 26px;
    font-weight: $font-weight-bold;
    line-height: 26px;
    color: $color-navyblue;
    text-transform: uppercase;
  }

  &__subheading {
    font-size: 16px;
    font-weight: $font-weight-medium;
    line-height: 16px;
    color: $color-green;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 8px;
  }

  &__divider {
    width: 50px;
    height: 4px;
    background-color: $color-green;
    border-radius: 6px;
    margin: 25px 0;

    &--centered {
      margin: 25px auto;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: $font-weight-regular;
    line-height: 25px;
    color: $color-navyblue;
  }
}

@media (min-width: $mq-sm) {
  .section {
    &__heading {
      font-size: 36px;
      line-height: 36px;
    }
  }
}