.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -10px 35px -10px;

  &__item {
    width: 100%;
    padding-bottom: 10px;
  }

  &__link {
    margin: 10px;
    display: block;
    position: relative;

    &:hover,
    &:focus {
      .gallery__logo-wrapper {
        opacity: 1;
      }
    }
  }

  &__thumbnail {
    height: 300px;
    background-color: $color-white;
    display: block;
    overflow: hidden;
  }

  &__logo-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .2s ease;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 0;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: $color-navyblue;
      position: absolute;
      opacity: 1;
      z-index: -1;
      top: 0;
      left: 0;
    }
  }

  &__logo {
    max-width: 70%;
    max-height: 70%;
    margin: auto;
  }

  &__image {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}

@media (min-width: $mq-sm) {
  .gallery {
    &__item {
      width: 50%;
    }

    &__thumbnail {
      height: 280px;
    }
  }
}

@media (min-width: $mq-md) {
  .gallery {
    margin: -10px 15px 70px 15px;

    &__thumbnail {
      height: 320px;
    }
  }
}

@media (min-width: $mq-lg) {
  .gallery {
    margin: -10px -10px 70px -10px;

    &__item {
      width: 25%;
    }

    &__thumbnail {
      height: 290px;
    }
  }
}

@media (min-width: $mq-xlg) {
  .gallery {
    margin: -10px 15px 70px 15px;
  }
}